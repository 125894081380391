import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  key: 0,
  class: "footer"
}
const _hoisted_3 = {
  key: 0,
  class: "right"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PremiumIcon = _resolveComponent("PremiumIcon")!
  const _component_ModalCloseButton = _resolveComponent("ModalCloseButton")!
  const _component_PlainModal = _resolveComponent("PlainModal")!

  return (_openBlock(), _createBlock(_component_PlainModal, {
    id: _ctx.id,
    headless: true,
    class: _normalizeClass(["subscription-modal accent-premium no-padding", {
      'attach-bottom': _ctx.isMobile
    }])
  }, {
    default: _withCtx(() => [
      _createElementVNode("rbe-columns", null, [
        _createElementVNode("rbe-column", null, [
          _createElementVNode("rbe-subscription-header", null, [
            _renderSlot(_ctx.$slots, "title", {}, () => [
              _createElementVNode("h1", null, [
                _createElementVNode("span", null, _toDisplayString(_ctx.title), 1),
                _cache[0] || (_cache[0] = _createTextVNode()),
                _createVNode(_component_PremiumIcon, { size: "sm" })
              ])
            ], true),
            _cache[2] || (_cache[2] = _createTextVNode()),
            (!_ctx.$slots.title)
              ? (_openBlock(), _createElementBlock("section", _hoisted_1, [
                  _createElementVNode("p", null, _toDisplayString(_ctx.subtitle), 1),
                  _cache[1] || (_cache[1] = _createTextVNode()),
                  (!_ctx.isMobile)
                    ? _renderSlot(_ctx.$slots, "header-append", { key: 0 }, undefined, true)
                    : _createCommentVNode("", true)
                ]))
              : _createCommentVNode("", true)
          ]),
          _cache[6] || (_cache[6] = _createTextVNode()),
          _createElementVNode("rbe-main-content", null, [
            _createElementVNode("rbe-mobile-main-content-append", null, [
              (_ctx.isMobile)
                ? _renderSlot(_ctx.$slots, "header-append", { key: 0 }, undefined, true)
                : _createCommentVNode("", true)
            ]),
            _cache[3] || (_cache[3] = _createTextVNode()),
            (_ctx.$slots.default)
              ? _renderSlot(_ctx.$slots, "default", { key: 0 }, undefined, true)
              : _renderSlot(_ctx.$slots, "left", { key: 1 }, undefined, true),
            _cache[4] || (_cache[4] = _createTextVNode()),
            _createElementVNode("rbe-mobile-main-content-append", null, [
              (_ctx.isMobile)
                ? _renderSlot(_ctx.$slots, "footer-prepend", { key: 0 }, undefined, true)
                : _createCommentVNode("", true)
            ])
          ]),
          _cache[7] || (_cache[7] = _createTextVNode()),
          (_ctx.$slots.action || _ctx.$slots['footer-prepend'])
            ? (_openBlock(), _createElementBlock("section", _hoisted_2, [
                (!_ctx.isMobile)
                  ? _renderSlot(_ctx.$slots, "footer-prepend", { key: 0 }, undefined, true)
                  : _createCommentVNode("", true),
                _cache[5] || (_cache[5] = _createTextVNode()),
                _renderSlot(_ctx.$slots, "action", {}, undefined, true)
              ]))
            : _createCommentVNode("", true)
        ]),
        _cache[8] || (_cache[8] = _createTextVNode()),
        (_ctx.$slots.right)
          ? (_openBlock(), _createElementBlock("rbe-column", _hoisted_3, [
              _renderSlot(_ctx.$slots, "right", {}, undefined, true)
            ]))
          : _createCommentVNode("", true)
      ]),
      _cache[9] || (_cache[9] = _createTextVNode()),
      _createVNode(_component_ModalCloseButton, {
        class: "close-button default-color plain",
        onClick: _ctx.closeAll
      }, null, 8, ["onClick"])
    ]),
    _: 3
  }, 8, ["id", "class"]))
}