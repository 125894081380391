<template>
  <PlainModal
    :id="id"
    :headless="true"
    class="subscription-modal accent-premium no-padding"
    :class="{
      'attach-bottom': isMobile
    }"
  >
    <rbe-columns>
      <rbe-column>
        <rbe-subscription-header>
          <slot name="title">
            <h1>
              <span>
                {{ title }}
              </span>
              <PremiumIcon size="sm" />
            </h1>
          </slot>

          <section v-if="!$slots.title">
            <p>
              {{ subtitle }}
            </p>

            <slot
              v-if="!isMobile"
              name="header-append"
            />
          </section>
        </rbe-subscription-header>

        <rbe-main-content>
          <rbe-mobile-main-content-append>
            <slot
              v-if="isMobile"
              name="header-append"
            />
          </rbe-mobile-main-content-append>

          <slot v-if="$slots.default" />
          <slot
            v-else
            name="left"
          />

          <rbe-mobile-main-content-append>
            <slot
              v-if="isMobile"
              name="footer-prepend"
            />
          </rbe-mobile-main-content-append>
        </rbe-main-content>

        <section
          v-if="$slots.action || $slots['footer-prepend']"
          class="footer"
        >
          <slot
            v-if="!isMobile"
            name="footer-prepend"
          />

          <slot name="action" />
        </section>
      </rbe-column>

      <rbe-column
        v-if="$slots.right"
        class="right"
      >
        <slot name="right" />
      </rbe-column>
    </rbe-columns>

    <ModalCloseButton
      class="close-button default-color plain"
      @click="closeAll"
    />
  </PlainModal>
</template>

<script lang="ts">
import {Component, mixins, Prop} from '@reedsy/studio.shared/utils/vue/decorators';
import PlainModal from '@reedsy/studio.shared/components/modals/templates/plain-modal.vue';
import {ClientSharedVue} from '@reedsy/studio.shared/client-shared-vue';
import PremiumIcon from '@reedsy/studio.shared/components/subscriptions/premium-icon.vue';
import ModalCloseButton from '@reedsy/studio.shared/components/modals/components/modal-close-button.vue';
import ModalMixin from '@reedsy/studio.shared/components/modals/mixins/modal-mixin';
import {$lazyInjectStore} from '@reedsy/studio.shared/inversify.config';
import {SharedStoreName} from '@reedsy/studio.shared/store/store-name';
import {SharedBrowserModule} from '@reedsy/studio.shared/store/modules/browser';

@Component({
  components: {
    ModalCloseButton,
    PlainModal,
    PremiumIcon,
  },
})
export default class SubscriptionBaseModal extends mixins(ModalMixin, ClientSharedVue) {
  @$lazyInjectStore(SharedStoreName.Browser)
  public readonly $browser: SharedBrowserModule;

  @Prop({required: false})
  public title: string;

  @Prop({required: false})
  public subtitle: string;

  public get isMobile(): boolean {
    return this.$browser.screen.lessThan.md;
  }

  public closeAll(): void {
    this._sharedModals.CLOSE_ALL();
  }
}
</script>

<style lang="scss" scoped>
.subscription-modal {
  --column-padding: #{$space-xl};
  --selection-background: var(--local-color-muted);

  width: min(70rem, 100vw);
  overflow: hidden;

  &.equal-columns {
    rbe-columns {
      grid-auto-columns: 1fr 1fr;

      .right {
        margin-top: 4rem;
        border-top-right-radius: 0;
      }
    }

    &.attach-bottom .right {
      margin-top: 0;
    }
  }

  rbe-main-content {
    padding: 0 var(--column-padding);
  }

  &.selector-modal.attach-bottom {
    rbe-main-content {
      padding: $space-md;
      background-color: var(--reedsy-neutral-muted);
      max-width: 100vw;

      rbe-mobile-main-content-append {
        justify-self: center;

        &:first-child:not(:empty) {
          margin-bottom: $space-md;
        }

        &:last-child:not(:empty) {
          margin-top: $space-md;
        }
      }
    }

    .footer, rbe-subscription-header {
      background-color: var(--reedsy-plain);
      position: sticky;
    }

    .footer {
      box-shadow: $box-shadow-up-sm;
    }

    rbe-subscription-header {
      box-shadow: $box-shadow-sm;
    }
  }

  section {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .footer, rbe-subscription-header {
    padding: var(--column-padding);
  }

  rbe-subscription-header {
    gap: $space-sm;
    display: flex;
    flex-direction: column;
    top: 0;
    z-index: 1;
  }

  .footer {
    bottom: 0;
  }

  rbe-columns {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 2fr 1fr;
  }

  rbe-column {
    border-top-right-radius: $modal-border-radius;
    border-bottom-right-radius: $modal-border-radius;
    display: grid;
  }

  h1 {
    @include font-family($controls, bold);

    display: flex;
    align-items: center;
    gap: $space-md;
    justify-content: left;
    font-size: $font-size-xxl;
  }

  .right {
    background: var(--selection-background);
  }

  .close-button {
    position: absolute;
    top: $space-base;
    right: $space-base;
    box-shadow: $box-shadow-sm;
    z-index: 2;
  }

  // TODO: Get mobile/tablet designs
  @include screen-less-than(md) {
    h1 {
      flex-direction: row-reverse;
    }

    rbe-columns {
      grid-auto-flow: row;
      grid-template-columns: 1fr;

      .right {
        display: none;
      }
    }
  }
}
</style>
