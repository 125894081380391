<template>
  <section
    class="feature-summaries"
    :class="{
      'mobile-version': showMobileVersion,
    }"
  >
    <component
      :is="featureSummaryComponent"
      v-for="summary in summaries"
      :key="summary.product"
      :ref="summary.product"
      v-model="selectedProducts[summary.product]"
      :summary="summary"
      :interval="interval"
      :prices="prices"
      :is-preview="featurePreview"
    />
  </section>
</template>

<script lang="ts">
import {Component, Model, Prop} from '@reedsy/studio.shared/utils/vue/decorators';
import {ClientSharedVue} from '@reedsy/studio.shared/client-shared-vue';
import {SubscriptionProduct} from '@reedsy/utils.subscription';
import FeatureSummary from './feature-summary.vue';
import {FEATURE_SUMMARIES} from './feature-summaries';
import {IProductSummary} from './product-summary.interface';
import {IPriceOptionsResponse} from '@reedsy/studio.isomorphic/controllers/api/v1/subscriptions/i-price-options-response';
import {PropType} from 'vue';
import {IBillingInterval} from '@reedsy/schemas.editor-collections';
import {$lazyInjectStore} from '@reedsy/studio.shared/inversify.config';
import {SharedStoreName} from '@reedsy/studio.shared/store/store-name';
import {SharedBrowserModule} from '@reedsy/studio.shared/store/modules/browser';
import CompactFeatureSummary from '@reedsy/studio.shared/components/modals/components/subscription/features/compact-feature-summary.vue';

@Component({
  components: {
    FeatureSummary,
    CompactFeatureSummary,
  },
})
export default class FeatureSummaries extends ClientSharedVue {
  @Model({type: Object, default: {}})
  public selectedProducts: Partial<Record<SubscriptionProduct, boolean>>;

  @Prop({type: Boolean, default: false})
  public featurePreview: boolean;

  @Prop({type: Object as PropType<IPriceOptionsResponse>})
  public prices: IPriceOptionsResponse;

  @Prop({type: String as PropType<IBillingInterval>})
  public interval: IBillingInterval;

  @$lazyInjectStore(SharedStoreName.Browser)
  public readonly $browser: SharedBrowserModule;

  public get featureSummaryComponent(): string {
    return this.showMobileVersion ? 'CompactFeatureSummary' : 'FeatureSummary';
  }

  public get summaries(): ReadonlyArray<IProductSummary> {
    if (!this.featurePreview) return FEATURE_SUMMARIES;

    return FEATURE_SUMMARIES.filter((summary) => summary.product);
  }

  public get showMobileVersion(): boolean {
    return this.$browser.screen.lessThan.md;
  }
}
</script>

<style lang="scss" scoped>
.feature-summaries {
  display: flex;
  flex-direction: row;
  gap: $space-base;
  align-items: stretch;

  & > * {
    flex: 1 0 0;
  }

  &.mobile-version {
    gap: $space-md;
    flex-direction: column;
  }
}
</style>
