<template>
  <SubscriptionBaseModal
    :id="id"
    class="subscription-payment-modal equal-columns"
  >
    <template #title>
      <rbe-subscription-back>
        <button
          type="button"
          class="button-with-icon"
          @click="backToPreview"
        >
          <VuiIconArrowLeft />
          <span>Go back</span>
        </button>
      </rbe-subscription-back>
    </template>

    <rbe-subscription-payment>
      <rbe-payment-section>
        <h1>
          Confirm your subscription
        </h1>
        <p>
          Please enter your card details to enable the add-ons you selected.
        </p>
      </rbe-payment-section>

      <rbe-payment-section>
        <h2>Billing frequency</h2>
        <rbe-selected-product class="flex-justified">
          <span class="font-weight-bold">{{ intervalTitle }}</span>

          <!-- TODO: [subscriptions]: calculate this value from live pricing -->
          <span
            class="reedsy-accented interval-discount"
            :class="{'neutral-highContrast font-weight-normal': interval === 'month'}"
          >
            {{ interval === 'year' ? 'Annual saving applied' : 'No discount' }}
          </span>
        </rbe-selected-product>
      </rbe-payment-section>

      <rbe-payment-section>
        <h2>Your subscription</h2>
        <rbe-selected-products class="flex-top">
          <rbe-selected-product
            v-for="product in activeProductsInfo"
            :key="product"
            class="flex-justified"
          >
            <span class="font-weight-bold">{{ product.title }}</span>
            <SubscriptionPrice
              :price="price.products[product.product].price"
              :currency="price.currency"
              :interval="price.interval"
            />
          </rbe-selected-product>
        </rbe-selected-products>
      </rbe-payment-section>

      <SubscriptionPriceBreakdown
        :calculated-price="price"
        compact
      />
    </rbe-subscription-payment>

    <template #right>
      <form
        v-if="shouldShowPaymentSetup"
        ref="payment-form"
        class="flex-top payment-form"
        @submit.prevent="subscribe"
      >
        <Panel class="payment-method-panel">
          <rbe-payment-methods-selector
            class="flex-top"
          >
            <div
              v-if="!isAttached"
              class="flex-centered"
            >
              <VuiLoadingIndicator ref="loader" />
            </div>
            <div
              v-show="isAttached"
              id="stripe-payment-method"
            />
          </rbe-payment-methods-selector>
        </Panel>
        <LoadingButton
          ref="subscribe-button"
          :loading="isProcessingPayment"
          :disabled="!clientSecret"
          class="button accent-premium"
          type="submit"
        >
          Subscribe &amp; enable add-ons
        </LoadingButton>
      </form>
      <div
        v-else
        class="flex-top payment-form"
      >
        <Panel class="payment-method-panel">
          <!-- TODO: Need ask Matt for designs / show current payment methods selector -->
          We will charge using your default payment method. You can control them
          <a
            ref="payment-settings-link"
            :href="billingPortalUrl"
            target="_blank"
            class="link"
          >
            here</a>.
        </Panel>
        <LoadingButton
          ref="update-subscription-button"
          :loading="isProcessingPayment"
          class="button accent-premium"
          @click="updateSubscription"
        >
          Update subscription
        </LoadingButton>
      </div>
    </template>
  </SubscriptionBaseModal>
</template>

<script lang="ts">
import {Component, mixins, Prop} from '@reedsy/studio.shared/utils/vue/decorators';
import ModalMixin from '@reedsy/studio.shared/components/modals/mixins/modal-mixin';
import {ClientSharedVue} from '@reedsy/studio.shared/client-shared-vue';
import SubscriptionBaseModal from '@reedsy/studio.shared/components/modals/components/subscription/subscription-base.vue';
import {PropType} from 'vue';
import {ISubscriptionPaymentModalArgs} from './subscription-payment-modal-args.interface';
import {FEATURE_SUMMARIES} from './feature-summaries';
import {IProductSummary} from './product-summary.interface';
import {IBillingInterval} from '@reedsy/schemas.editor-collections';
import {ICalculatePriceResponse} from '@reedsy/studio.isomorphic/controllers/api/v1/subscriptions/i-calculate-price-response';
import SubscriptionPriceBreakdown from '@reedsy/studio.shared/components/subscriptions/subscription-price-breakdown.vue';
import SubscriptionPrice from '@reedsy/studio.shared/components/subscriptions/subscription-price.vue';
import {$lazyInject, $lazyInjectStore} from '@reedsy/studio.shared/inversify.config';
import {ISubscriptionModalService} from '@reedsy/studio.shared/services/subscriptions/i-subscription-modal-service';
import {SharedStoreName} from '@reedsy/studio.shared/store/store-name';
import {SharedSubscriptionModule} from '@reedsy/studio.shared/store/modules/subscription';
import {SubscriptionIntentType} from '@reedsy/studio.isomorphic/controllers/api/v1/subscriptions/subscription-setup-type';
import Panel from '@reedsy/studio.shared/components/panel/panel.vue';
import {Stripe, StripeElements} from '@stripe/stripe-js';
import {Provider} from '@reedsy/utils.types';
import {NotifyError} from '@reedsy/studio.shared/utils/decorators/notify-error';
import LoadingButton from '@reedsy/studio.shared/components/loading-button.vue';
import Notify from '@reedsy/studio.shared/services/notify/notify';
import {SubscriptionProduct} from '@reedsy/utils.subscription';
import {objectKeys} from '@reedsy/utils.object';
import {ICurrentSubscription} from '@reedsy/studio.shared/store/modules/subscription/current-subscription.interface';
import Navigation from '@reedsy/studio.shared/services/navigation';
import {getStripeElements} from '@reedsy/studio.shared/services/stripe/get-stripe-elements';
import {setLoadingFlag} from '@reedsy/utils.disposable';

@Component({
  components: {
    Panel,
    SubscriptionBaseModal,
    SubscriptionPrice,
    SubscriptionPriceBreakdown,
    LoadingButton,
  },
})
export default class SubscriptionPayment extends mixins(ModalMixin, ClientSharedVue) {
  @Prop({type: Object as PropType<ISubscriptionPaymentModalArgs>, default: {}})
  public context: ISubscriptionPaymentModalArgs;

  @$lazyInject('SubscriptionModal')
  public $subscriptionModal: ISubscriptionModalService;

  @$lazyInjectStore(SharedStoreName.Subscription)
  public $subscription: SharedSubscriptionModule;

  @$lazyInject('StripeProvider')
  public readonly stripeProvider: Provider<Stripe>;

  @$lazyInject('Navigation')
  public $navigation: Navigation;

  public readonly cancelable = true;

  public clientSecret: string = null;
  public intentType: SubscriptionIntentType = null;
  public stripeElements: StripeElements = null;
  public stripe: Stripe = null;
  public isProcessingPayment = false;
  public isAttached = false;

  public get currentSubscription(): ICurrentSubscription {
    return this.$subscription.currentSubscriptionInfo;
  }

  public get billingPortalUrl(): string {
    return this.$navigation.billingPortalUrl;
  }

  public get hasActiveSubscription(): boolean {
    return !!this.currentSubscription;
  }

  public get isTrial(): boolean {
    return this.$subscription.isTrial;
  }

  public get shouldShowPaymentSetup(): boolean {
    if (!this.hasActiveSubscription) return true;
    if (!this.isTrial) return false;

    return !this.currentSubscription.hasPaymentMethodSet;
  }

  public get price(): ICalculatePriceResponse {
    return this.context.price;
  }

  public get interval(): IBillingInterval {
    return this.price.interval;
  }

  public get intervalTitle(): string {
    return this.interval === 'year' ? 'Annually' : 'Monthly';
  }

  public get activeProductsInfo(): ReadonlyArray<IProductSummary> {
    return FEATURE_SUMMARIES.filter(({product}) => this.price.products[product]);
  }

  public get selectedProducts(): SubscriptionProduct[] {
    return objectKeys(this.price.products);
  }

  private get redirectUrl(): string {
    const redirectUrlObject = new URL(window.location.href);
    redirectUrlObject.searchParams.append('paymentResult', '1');
    return redirectUrlObject.toString();
  }

  public backToPreview(): void {
    this.close();
  }

  public async mounted(): Promise<void> {
    await this.$subscription.refreshCurrentSubscriptionInfo();

    if (!this.shouldShowPaymentSetup) return;

    await Promise.all([
      this.loadClientSecret(),
      this.loadStripe(),
    ]);

    this.stripeElements = getStripeElements(this.stripe, {
      clientSecret: this.clientSecret,
    });
    this.attachPaymentStripeElement();
  }

  public async updateSubscription(): Promise<void> {
    using setPaymentFinished = setLoadingFlag(this, 'isProcessingPayment');
    await this.processUpdate();
    this._sharedModals.CLOSE_ALL();
    setPaymentFinished();
  }

  public async subscribe(): Promise<void> {
    using setPaymentFinished = setLoadingFlag(this, 'isProcessingPayment');
    await this.processPayment();
    setPaymentFinished();
  }

  @NotifyError('Cannot process the subscription update')
  public async processUpdate(): Promise<void> {
    await this.$subscription.updateSubscription({
      interval: this.interval,
      products: this.selectedProducts,
    });
    await this.$subscription.refreshCurrentSubscriptionInfo();
    Notify.success({message: 'Subscription updated successfully'});
  }

  @NotifyError('Cannot process the payment')
  public async processPayment(): Promise<void> {
    if (this.$subscription.isTrial) {
      await this.$subscription.updateSubscription({
        interval: this.interval,
        products: this.selectedProducts,
      });
    }
    await this.confirmPaymentMethod();
    await this.$subscription.refreshCurrentSubscriptionInfo();
    this._sharedModals.CLOSE_ALL();
    this.$subscriptionModal.openPaymentSuccess({products: new Set(this.selectedProducts)});
  }

  private async confirmPaymentMethod(): Promise<void> {
    const methodMapping = {
      [SubscriptionIntentType.Payment]: 'confirmPayment',
      [SubscriptionIntentType.Setup]: 'confirmSetup',
    } as const satisfies Record<SubscriptionIntentType, keyof Stripe>;
    const method = methodMapping[this.intentType];

    if (!method) {
      throw new Error('Unknown intent type');
    }

    const result = await this.stripe[method]({
      elements: this.stripeElements,
      confirmParams: {
        return_url: this.redirectUrl,
      },
      redirect: 'if_required',
    });

    // eslint-disable-next-line @typescript-eslint/only-throw-error
    if (result.error) throw result.error;
  }

  private async attachPaymentStripeElement(): Promise<void> {
    const paymentElement = this.stripeElements.create('payment');
    paymentElement.mount('#stripe-payment-method');
    this.isAttached = true;
  }

  @NotifyError('Cannot initialise payment')
  private async loadClientSecret(): Promise<void> {
    if (!this.$subscription.isTrial) {
      await this.$subscription.createDraftSubscription({
        products: this.selectedProducts,
        interval: this.interval,
      });
    }
    const response = await this.$subscription.fetchIntentClientSecret();
    this.clientSecret = response.clientSecret;
    this.intentType = response.type;
  }

  @NotifyError('Cannot initialise payment')
  private async loadStripe(): Promise<void> {
    this.stripe = await this.stripeProvider();
  }
}
</script>

<style lang="scss" scoped>
.subscription-payment-modal {
  --selection-background: var(--reedsy-neutral);

  width: min(52rem, 100vw);

  rbe-subscription-payment {
    display: flex;
    flex-direction: column;
    gap: $space-base;
    padding-bottom: var(--column-padding);
  }

  h1, h2 {
    @include font-family($controls, bold);
  }

  h1 {
    font-size: $font-size-xxl;
  }

  h2 {
    font-size: $font-size-base;
    margin-bottom: $space-sm;
  }

  rbe-selected-products {
    gap: $space-sm;
  }

  rbe-selected-product {
    padding: $space-md $space-base;
    border-radius: $border-radius-base;
    border: $border-lg;

    .interval-discount {
      @include font-family($controls, bold);

      color: var(--local-color-emphasis);
    }

    :deep(.skeleton-loader) {
      width: 5rem;
    }
  }

  &.equal-columns :deep(.right) {
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: 0;
  }

  .payment-form {
    height: 100%;
    margin: $space-base;
    box-sizing: border-box;
    gap: $space-base;

    .payment-method-panel {
      flex: 1;
    }

    rbe-payment-methods-selector {
      gap: $space-md;
    }
  }
}
</style>
